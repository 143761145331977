/* eslint-disable react/no-unescaped-entities */
import React, { useMemo, useState, useRef, useEffect } from 'react';
import { AnimatePresence, motion, useSpring, useTransform } from 'framer-motion';
import { CTA, IImage } from '../types';
import { MotionImage } from './AnimatedImage';
import { Button } from '.';
import { makeVariants } from '../utils/makeVariants';
import { useScrolledTheme, useInViewScroll, SplitH2, useSanitizedTranslation } from 'ui';
import Head from 'next/head';
import { BazaarVoiceBlock } from './BazaarVoiceBlock';
import Image from 'next/image';
import devicesJSON from 'ui/devices.json'


// const DeviceOpenGraph = ({heading, description, images, url}) => {
//   const getGalleryJsonLd = () => {
//     const obj = {
//       '@context': 'https://schema.org',
//       '@type': 'Product',
//       '@id': 'https://www.xumo.com' + url,
//       brand: 'Xumo',
//       name: heading,
//       description: description,
//       image: images.src,
//     };

//     return {
//       __html: JSON.stringify(obj),
//     };
//   };

//   return (
//     <>
//       <Head>
//         <script type="application/ld+json" dangerouslySetInnerHTML={getGalleryJsonLd()} />
//       </Head>
//     </>
//   )
// }

// export type ProductDetailBlockProps = {
//   deviceId: string;
// };

// // TODO: add more props
// //const ShopButton: React.FC<{ image: IImage }> = ({ image }) => {
// const ShopButton = ({ image, url, name, label, bvValue }) => {
//   return (
//     <div className="inline-block">
//       <Button
//         label={label}
//         href={url}
//         disabled={!url}
//         buttonType={!url ? "submit-primary" : "link-primary"}
//         onClick={evt =>{
//           evt.preventDefault();

//           BV && BV.pixel.trackConversion({
//             type: 'Shop now',
//             label: name,
//             value: bvValue,
//           });

//           console.log('i was clicked', BV, BV && BV.pixel)

//           window.open(evt.currentTarget.href, '_blank');
//         }}
        
//         styleOverrides={{
//           tailwind: 
//             'inline-block px-6 bg-xumoBerry disabled:bg-xumoSmoke cursor-pointer disabled:cursor-default disabled:text-xumoGrey disabled:drop-shadow-none',
//           css: {
//             backgroundColor: '',
//             color: 'white',
//           },
//         }}
//       />
//     </div>
//   );
// };

export const ProductDetailBlock = ({deviceId = 0}) => {

  // const { t } = useSanitizedTranslation()

  // const { 
  //   productId = '001', 
  //   features, 
  //   buttons, 
  //   sizes, 
  //   heading,
  //   brand, 
  //   logo,
  //   images,
  //   redirectUrl
  // } = devicesJSON[deviceId]

  // const ref = useRef()
  // const { componentInView, colors } = useScrolledTheme({
  //   ref,
  //   background: '#E0E7ED',
  //   accent: '#2AAC00',
  //   text: '#000000',
  // });

  return <></>
};