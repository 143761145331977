import { useRef } from 'react';
import { ContentBlock, MappingContentBlock } from 'ui/utils/mapping';
import { v4 as uuid } from 'uuid';
import {
  ChevronBlock,
  CtaColumns,
  Ecosystem,
  Footer,
  LeadGenForm,
  NavigationHeader,
  SocialProof,
  SwirlBlock,
  TwoColumnBlock,
  UseCases,
  VerboseDeviceShowcase,
  ColorCopyGrid,
  NewsBlock,
  FullbleedHero,
  BubbleBlock,
  SubscribeBlock,
  QuoteBlock,
  FaqBlock,
  ChannelBlock,
  TvSplashBlock,
  ChannelSearchBlock,
  SignUpPopup,
  Carousel,
  SliderBlock,
  ContentBlock as ContentBlockComponent,
  LandingHero,
  RotatingScreensBlock,
  OffersBlock,
  DeviceListBlock,
  PressBlock,
  VideoModal,
  StatsBlock,
  GlobeStatsBlock,
  TestimonialsBlock,
  ImageHoldingShapeBlock,
  VideoTabsBlock,
  WhereToBuy,
  FullbleedHeroAlt,
  FloatingButton,
  VideoHero,
  VideoCarouselBlock,
  CurvedShowcaseBlock,
  PeacockPromo,
  EventsBlock,
  EnterpriseHero,
  ProductDetailBlock,
  ProductListingBlock,
  BazaarVoiceBlock,
  AdvertisingHero,
  AdvertisingStats,
  Hero,
  TvHero,
  StreamHero,
  TypographyStyleGuide,
  CtaTileBlock,

  ImageColumnsBlock,
  PromoBlock
} from 'ui/components';

import Anchor from './Anchor';
import Disclaimer from './Disclaimer';

const Null = () => <div />;

export const PageContent: React.FC<{
  page: { content: MappingContentBlock[] };
}> = ({ page }) => {
  const uniqueId = useRef(uuid());

  const componentTable = {
    [ContentBlock.SocialProof]: Null,
    [ContentBlock.ChevronBlock]: ChevronBlock,
    [ContentBlock.FloatingButton]: Null,
    [ContentBlock.Footer]: Footer,
    [ContentBlock.NavigationHeader]: NavigationHeader,
    [ContentBlock.VerboseDeviceShowcase]: Null,
    [ContentBlock.LeadGenForm]: LeadGenForm,
    [ContentBlock.SwirlBlock]: Null,
    [ContentBlock.BulletBlock]: Null, //BulletBlock,
    [ContentBlock.UseCases]: Null,
    [ContentBlock.TwoColumn]: Null,
    [ContentBlock.CtaColumns]: CtaColumns,
    [ContentBlock.Ecosystem]: Null,
    [ContentBlock.ColorCopyGrid]: Null,
    [ContentBlock.NewsBlock]: NewsBlock,
    [ContentBlock.FullbleedHero]: FullbleedHeroAlt,
    [ContentBlock.BubbleBlock]: BubbleBlock,
    [ContentBlock.SubscribeBlock]: Null,
    [ContentBlock.QuoteBlock]: Null,
    [ContentBlock.FaqBlock]: FaqBlock,
    [ContentBlock.ChannelBlock]: ChannelBlock,
    [ContentBlock.TvSplashBlock]: TvSplashBlock,
    [ContentBlock.ChannelSearchBlock]: ChannelSearchBlock,
    [ContentBlock.SignUpPopup]: SignUpPopup,
    [ContentBlock.Carousel]: Null,
    [ContentBlock.ContentBlock]: Null,
    [ContentBlock.SliderBlock]: Null,
    [ContentBlock.RadioButtonGallery]: DeviceListBlock,
    [ContentBlock.LandingHero]: Hero, //
    [ContentBlock.RotatingScreensBlock]: RotatingScreensBlock,
    [ContentBlock.OffersBlock]: OffersBlock,
    [ContentBlock.Anchor]: Anchor,
    [ContentBlock.PressBlock]: PressBlock,
    [ContentBlock.Disclaimer]: Disclaimer,
    [ContentBlock.VideoModal]: VideoModal,
    [ContentBlock.AnimatedStatsBlock]: StatsBlock,
    [ContentBlock.GlobeBlock]: GlobeStatsBlock,
    [ContentBlock.TestimonialsBlock]: Null, //TestimonialsBlock,
    [ContentBlock.VideoHero]: VideoHero,
    [ContentBlock.ImageHoldingShapeBlock]: ImageHoldingShapeBlock,

    ['VideoTabsBlock']: VideoTabsBlock,
    ['WhereToBuy']: WhereToBuy,
    ['VideoCarouselBlock']: Null, //VideoCarouselBlock,
    ['CurvedShowcaseBlock']: Null, // CurvedShowcaseBlock,
    ['PeacockPromo']: PeacockPromo,
    ['EventsBlock']: EventsBlock,
    ['EnterpriseHero']: EnterpriseHero,
    ['ProductDetailBlock']: ProductDetailBlock,
    ['ProductListingBlock']: ProductListingBlock,
    ['BazaarVoiceBlock']: BazaarVoiceBlock,
    ['AdvertisingHero']: AdvertisingHero,
    ['AdvertisingStats']: AdvertisingStats,
    ['TypographyGuide']: TypographyStyleGuide,

    
    ['PromoBlock']: PromoBlock,
    ['ImageColumnsBlock']: ImageColumnsBlock,
    ['CtaTilesBlock']: CtaTileBlock,
    
    
    ['Hero']: Hero,
    ['TvHero']: TvHero,
    ['StreamHero']: StreamHero
  };

  const hasHeader = page.content[0]?.component === ContentBlock.NavigationHeader;

  return (
    <>
      {page.content.map((c, id) => {
        console.log('comp', c.component) 
        const Component = componentTable[c.component as keyof typeof componentTable];
        return Component && <Component key={c.component + id + uniqueId} {...(c.props as any)} headingOne={hasHeader ? id === 1 : id === 0} />;
      })}
    </>
  );
};
